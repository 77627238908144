<template>
<!-- 设备详情 -->
 <div class="warehousing_record">
   <!-- 头部 -->
   <div class="land_head">
   	<!-- 搜索 -->
   	<div class="select">
		  <!-- 时间选择 -->
		  <el-date-picker
		        v-model="times"
		        type="daterange"
		        align="right"
		        unlink-panels
		        range-separator="至"
		        start-placeholder="开始日期"
		        end-placeholder="结束日期"
		  	  @change="timeChange"
			  :default-time="['00:00:00','23:59:59']"
		  	  value-format="timestamp"
		      :picker-options="pickerOptions">
		  </el-date-picker>
		  <div class="search" @click="search">查 询</div>
   	</div>
   	<div class="land_title">设备详情</div>
	<!-- <div class="add" @click="add" >
		<img src="../../../assets/del.png" />
		<span>新增</span>		
	</div>	 -->
   	<div class="back" @click="back">
   		<i class="el-icon-back"></i>
   		<span style="margin-left: 4px;">返回</span>
   	</div>
   </div>
   <div class="box">
	   <!-- 表格 -->
	   <div class="table_container">
	   	   <div class="title">
	   	   	<div class="t_l"></div>
	   	   	<div class="t_r">
	   	   		<div>设备信息</div>
	   	   		<div class="line">
	   	   			<div class="line1">
	   	   				<div class="line3"></div>
	   	   			</div>
	   	   			<div class="line2">
	   	   				<div class="line4"></div>
	   	   			</div>
	   	   		</div>
	   	   	</div>
	   	   </div>
	   	   <el-table
	   	     :data="tableData"
	   	      size="mini"
	   	      fit
	   	   	  stripe
	   	     :default-sort="{prop: 'date', order: 'descending'}"
	   	     :row-style="{height: '40px'}"
	   	     :cell-style="{padding: '0'}"
	   		 height="400"
	   	     style="width: 100%"
	   	    >
	   	   	 <el-table-column type="index" label="序号" align="center"></el-table-column>
			 <el-table-column prop="sub_time" label="上传时间" align="center" width="100">
			 	 <template slot-scope="scope">
			 	 	<div>{{timeFormate(scope.row.sub_time)}}</div>
			 	 </template>
			  </el-table-column>
			 <el-table-column v-for="(item,index) in tableLabel" :key='index' :prop="item.prop" :label="item.label" align="center" min-width="150"></el-table-column>
	   	    </el-table>
	   	    <!-- 分页 -->
	   	    <el-row class="page">
	   	        <el-col>
	   	          <div class="pagination">
	   	            <el-pagination
	   	              v-if="paginations.total > 0"
	   	              @current-change="handleCurrentChange"
	   	              :current-page.sync="paginations.page_index"
	   	              :page-size="paginations.page_size"
	   	              layout="prev, pager, next, jumper"
	   	              :total="paginations.total">
	   	            </el-pagination>
	   	          </div>
	   	        </el-col>
	   	      </el-row>
	    
	   </div>
	   <div class="detail_right">
		  <!-- 设备信息 -->
		  <div class="b_middle_item">
		  	 <div class="title">
		  	 	<div class="t_l"></div>
		  	 	<div class="t_r">
		  	 		<div>设备信息</div>
		  	 		<div class="line">
		  	 			<div class="line1">
		  	 				<div class="line3"></div>
		  	 			</div>
		  	 			<div class="line2">
		  	 				<div class="line4"></div>
		  	 			</div>
		  	 		</div>
		  	 	</div>			   
		  	 </div>
			 <div  class="data_item">
				<span class="quan blue"></span>
				<span style="margin-right: 20px;">设备名称</span>
				<span class="data_number" v-if="detailInfo.device_info.device_name">{{detailInfo.device_info.device_name}}</span>
			 </div>
			 <div  class="data_item">
			 	<span class="quan"></span>
				<span style="margin-right: 20px;">所属地块</span>
			 	<span class="data_number" v-if="detailInfo.device_info.yard_name">{{detailInfo.device_info.yard_name}}</span>
			 </div>
			 <div  class="data_item">
			 	<span class="quan"></span>
				<span style="margin-right: 20px;">设备型号</span>
			 	<span class="data_number" v-if="detailInfo.device_info.device_model">{{detailInfo.device_info.device_model}}</span>
			 </div>
			 <div  class="data_item">
			 	<span class="quan red"></span>
				<span style="margin-right: 20px;">在线状态</span>
			 	<span class="data_number" v-if="detailInfo.device_info.device_status">{{detailInfo.device_info.device_status==1?'在线':'离线'}}</span>
			 </div>
	
		  </div>
		  <!-- 设备参数 -->
		  <div class="b_middle_item">
		  	 <div class="title">
		  	 	<div class="t_l"></div>
		  	 	<div class="t_r">
		  	 		<div>设备参数</div>
		  	 		<div class="line">
		  	 			<div class="line1">
		  	 				<div class="line3"></div>
		  	 			</div>
		  	 			<div class="line2">
		  	 				<div class="line4"></div>
		  	 			</div>
		  	 		</div>
		  	 	</div>			   
		  	 </div>
		  	 <div class="t_box" v-if="detailInfo.device_info_value">
				 <!-- <div v-for="(item_label,index_label) in labelList1" :key='index_label' class="data_item"> -->
				 <div v-for="(item_label,index_label) in tableLabel" :key='index_label' class="data_item" style="margin-right: 5%;">
				 	<span class="quan blue"></span>
				    <div style="width: 160px;">{{item_label.label}}<i></i></div>
					<span class="data_number">{{detailInfo.device_info_value[item_label.prop]}}</span>
				 </div>
			 </div>
			 
		  </div>
		  <!--本月设备数据上传次数统计图-->
		  <div class="b_middle_item">
		  	  <div class="title">
		  	  	<div class="t_l"></div>
		  	  	<div class="t_r">
		  	  		<div>本月设备数据上传次数统计图</div>
		  	  		<div class="line">
		  	  			<div class="line1">
		  	  				<div class="line3"></div>
		  	  			</div>
		  	  			<div class="line2">
		  	  				<div class="line4"></div>
		  	  			</div>
		  	  		</div>
		  	  	</div>			   
		  	  </div>
		  	  <div id="myChart_equipment" ref='chart'></div>
		  </div>
	   </div>
   </div>
   

  <!-- 出库数量填写页面 -->
  <div class="marks" v-if="dialog.show">
  	<WarehousingRecordDetail :dialog="dialog" :form="form" @update="getProfile"></WarehousingRecordDetail>
  </div>

</div>
</template>

<script>
import { getDeviceInfoList,getDeviceInfo,top_key} from '@/api/index'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
import WarehousingRecordDetail from '../../components/InventoryManagement/WarehousingRecordDetail.vue'
export default {
  name: 'WarehousingRecord',
  data () {
    return {
		isShow:false,
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      tableData: [],
	  tableLabel: [
		  {label:'日期',prop:'value1'},
		  {label:'日期1',prop:'value2'},
		  {label:'日期2',prop:'value3'},
		  {label:'日期3',prop:'value4'}
	  ],
      // 需要给分页组件传的信息
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 1, // 总数
        page_size: 10 // 1页显示多少条
      },
	  dialog:{
	    show: false,
	    title: '',
	    option: ''
	  },
	  form: {},
	  times:'',//时间选择
	  pickerOptions: {
	      shortcuts: [{
	        text: '最近一周',
	        onClick(picker) {
	          const end = new Date();
	          const start = new Date();
	          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
	          picker.$emit('pick', [start, end]);
	        }
	      }, {
	        text: '最近一个月',
	        onClick(picker) {
	          const end = new Date();
	          const start = new Date();
	          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
	          picker.$emit('pick', [start, end]);
	        }
	      }, {
	        text: '最近三个月',
	        onClick(picker) {
	          const end = new Date();
	          const start = new Date();
	          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
	          picker.$emit('pick', [start, end]);
	        }
	      }] 
	  },
	  detailInfo:{
		  device_info:{
			  device_name:''
		  }
	  },
	  labelList1:['参数1','参数2','参数3','参数4','参数5','参数6','参数7','参数8','参数9','参数10','参数11','参数12','参数13','参数14','参数15'],
	  warnList:[
	  	{time:1636447332,txt:'1号日光棚土壤pH值 < 7',type:1},
	  	{time:1636447332,txt:'1号大田温度 < 0°C',type:2}
	  ],
	  xAxisName:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
	  data4:[71,66, 18, 32, 24, 81, 58, 66, 25, 48, 56,66],//本月设备数据上传次数统计图
	  timeId:[],//定时器集合
    }
  },
  components:{
  	  WarehousingRecordDetail
  },
  mounted () {
	// console.log(this.$route.query.id)
	this.getLabel()
	this.getDetailData()
  },
  destroyed() {
  	// console.log('destroyed')
  	// console.log(this.timeId)
  	// 清空该页面中所有的的定时器
  	for(let i=0;i<this.timeId.length;i++){
  	   clearInterval(this.timeId[i])
  	}	 
	// 销毁图表实列，释放echarts资源
	echarts.dispose(this.myChart);
	this.myChart = null
  },
  methods: {
	// 获取动态表头名称
	getLabel () {
	  console.log("获取表头名称")
	  // 获取表格数据
	  const data = {
	    device_id:this.$route.query.id,
	  }
	  // 请求数据
	  top_key(data)
	    .then((res) => {
		  console.log(res)
		  if(res.code=='200'){
			  this.tableLabel=res.data
			  // 加载数据表格
			  this.getProfile(1)
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getProfile (pages) {
	  // console.log("获取设备详情列表")
	  // 获取表格数据
	  const data = {
	    device_id:this.$route.query.id, 
		start_time:this.times?this.times[0]/1000:'',
		end_time:this.times?this.times[1]/1000:'',
		page: pages? pages:this.pagesNumber,
	    limit: '10',
	  }
	  // 请求数据
	  getDeviceInfoList(data)
	    .then((res) => {
		  // console.log(res)
		  // console.log(res.data)
		  this.paginations.total=res.count
		  this.tableData=res.data
		  // console.log("总共有"+ this.paginations.total+"条数据")
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getDetailData () {
	  // console.log("获取设备详情")
	  // 获取表格数据
	  const data = {
	    device_id:this.$route.query.id, 
	  }
	  // 请求数据
	  getDeviceInfo(data)
	    .then((res) => {
		  // console.log(res)
		  // console.log(res.data)
		  this.detailInfo=res.data
		  this.data4=this.detailInfo.device_info_count
		  this.$nextTick(()=>{
			  this.drawLine()
		  })
	    })
	    .catch((err) => {
		  console.log(111)
	      console.log(err)
	    })
	},
	// 返回
	back(){
		this.$router.go(-1)
	},
	// 查询
	search(){
		this.getProfile (1)
	},
	// 时间选择器选中事件
	timeChange(e){
		console.log(e);
		console.log(this.value,this.times)
		// 更新表格内容
	},
	// 本月设备数据上传次数统计图
	drawLine() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart = echarts.init(document.getElementById('myChart_equipment'));
			           this.myChart.resize({
			             width: this.$refs.chart.clientWidth,
			             height: this.$refs.chart.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
					    // title: {
					    //     text: "各地区农机作业量",
					    //     x:'center',
					    //     textStyle: {
					    //         color: '#1FFDFD',
					    //         fontWeight: 'normal',
					    //         fontSize: 16
					    //     }
					    // },
					    grid: {
					        left: '1%',
					        right: '5%',
					        bottom: '5%',
							top:50,
					        containLabel: true
					    },
					    
					    tooltip: {
					        show: "true",
					        trigger: 'axis',
					        axisPointer: { // 坐标轴指示器，坐标轴触发有效
					            type: 'cross' // 默认为直线，可选为：'line' | 'shadow'
					        }
					    },
						 toolbox: {
						   feature: {
						 	magicType: { type: ['line', 'bar'] },
						     saveAsImage: {
						 		show:true,
						         backgroundColor :'balck',
						 		pixelRatio: 3
						 	}
						   },
						 },
					    yAxis: {
					        name:'次数',
					        nameTextStyle: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: '#fff'
					        },
					        type: 'value',
					        axisTick: {
					            show: false
					        },
					        axisLine: {
					            show: true,
					            lineStyle: {
					                color: '#535A6B',
					            },
					        },
					        splitLine: {
					            show: false,
					            lineStyle: {
					                color: '#FFFFFF',
					            }
					        },
							axisLabel: {
							    fontSize: 12,
							    fontFamily: 'Microsoft YaHei',
							    color: "#FFFFFF",
							},
					    },
					    xAxis: [
					        {
					            type: 'category',
					            axisTick: {
					                show: false
					            },
					            axisLine: {
					                show: true,
					                lineStyle: {
					                    color: '#535A6B',
					                }
					            },
					            axisLabel: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: "#FFFFFF",
					        },
					            data: this.xAxisName
					        }
					    
					    ],
					    series: [
					        {
					            name: '累计出勤次数（次）',
					            type: 'line',
								smooth: true,//设置折线的平滑度
								areaStyle: {
									//改变区域渐变颜色
									normal: {
									    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									            offset: 0,
									            color: 'rgba(0,255,230,1)'
									        }, {
									            offset: 1,
									            color: 'rgba(0,255,230,0.2)'
									        }]),            
									}									
								},
					            itemStyle: {
					                normal: {
					                    show: true,
					                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
					                        offset: 0,
					                        color: '#00FFE6'
					                    }, {
					                        offset: 1,
					                        color: '#007CC6'
					                    }]),
					                    barBorderRadius: 50,
					                    borderWidth: 0,
					                    borderColor: '#333',
					                }
					            },
					            label: {
					                normal: {
					                    show: true,
					                    position: 'top',
					                    textStyle: {
					                        color: '#fff'
					                    }
					                }
					            },
					            barWidth: '20%',
					            data: this.data4,
							    markPoint: {
							          data: [
							            { type: 'max', name: 'Max' },
							            { type: 'min', name: 'Min' }
							          ]
							        },
							   markLine: {
							       data: [{ type: 'average', name: 'Avg' }]
							   }
					        }
					    
					    ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart.setOption(option);
					   // //动态循环显示数据节点
					   // this.dataLableChange(myChart,option,4)
						   
	},
	// 动态循环显示数据节点方法
	dataLableChange(myChart,option,i){
			   // console.log('shenme')
			   //动态循环显示数据节点
			   var app = {
			       currentIndex: -1,
			   };
			   this.timeId[i]=setInterval(function() {
			   	   console.log('start'+i)
			       var dataLen = option.series[0].data.length;
			       // 取消之前高亮的图形
			       myChart.dispatchAction({
			           type: 'downplay',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			       app.currentIndex = (app.currentIndex + 1) % dataLen;
			   
			       // 高亮当前图形
			       myChart.dispatchAction({
			           type: 'highlight',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex,
			       });
			   
			       // 显示 tooltip
			       myChart.dispatchAction({
			           type: 'showTip',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			   
			   }, 5000); 
			   
	},
	
	
    handleCurrentChange (page) {
      const number = String(page)
	  this.pagesNumber=number
	  console.log("切换至"+this.pagesNumber+"页")
      // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  const data = {
		device_id:this.$route.query.id,
		start_time:this.times?this.times[0]/1000:'',
		end_time:this.times?this.times[1]/1000:'',
	    page: number,
	    limit: '10',
	  }
      console.log(data)
      // 发送请求
      // 请求数据
      getDeviceInfoList(data)
        .then((res) => {
          console.log(res)
      	  console.log(res.data)
		  this.tableData=res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},

  },
}
</script>
<style scoped>
  .warehousing_record{
	width: 100%;
	height: 100%;
	padding:0 30px 20px 30px;
	box-sizing: border-box;
	overflow: auto;
  }
  .land_head{
  	  width: 100%;
  	  height: 78px;
  	  margin-bottom: 30px;
  	  display: flex;
  	  align-items: flex-end;
  	  justify-content: start;
  	  position: relative;
  }
  .select{
  	  /* width: 600px; */
  	  height: 48px;
	  display: flex;
	  justify-content: start;
	  align-items: center;
  	  /* background-color: #1CCA81; */
  }
  .land_title{
  	  width: 554px;
  	  height: 78px;
  	  line-height: 78px;
  	  font-size: 28px;
  	  font-family: PingFang SC;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	  margin-left: 100px;
  	  letter-spacing: 2px;
  	  background: url(../../assets/title_bg.png) no-repeat center;
  	  background-size: contain;
	  position: absolute;
	  left: 50%;
	  transform: translateX(-50%);
  }
  .search{
  	width: 108px;
  	height: 46px;
  	background: #0B1326;
  	border: 1px solid #36CFC9;
  	font-size: 14px;
  	font-family: PingFang SC;
  	font-weight: bold;
  	color: #FFFFFF;
  	display: flex;
  	justify-content: center;
  	align-items: center;
  	cursor: pointer;
  	position: relative;
  	left:30px;
  	bottom:0;
  }
  .back{
  	  position: absolute;
  	  right: 0;
  	  bottom:0;
  	  width: 135px;
  	  height: 48px;
  	  line-height: 48px;
  	  font-size: 14px;
  	  font-family: PingFang SC;
  	  letter-spacing: 2px;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	  background: url(../../assets/back_bg.png) no-repeat center;
  	  background-size: cover;
  	  border: 1px solid #4B5052;
  	  display: flex;
  	  align-items: center;
  	  justify-content: center;
	  cursor: pointer;
  }
  .back i{
  	  font-size: 18px;
  }
  .box{
	  width: 100%;
	  height: calc(100% - 120px);
	  /* background-color: red; */
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
  }
  
  .warehousing_record .table_container{
	/* width: 100%;
	height: calc(100% - 170px); */
	width:calc(65% - 30px);
	height: 100%;
	background: rgba(10,23,56,0.7);
    padding:0px 20px 20px 20px;
	box-sizing: border-box;
  }
  .title{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: flex-end;
      aljustify-content: start;
      text-align: left;
  	  margin-top: 0;
	  margin-bottom: 2%;
    }
    .t_l{
    	  width: 33px;
    	  height: 34px;
    	  background: url(../../assets/jiankong/quan_o.png);
    	  background-size: contain;
    	  margin-right: 14px;
    }
    .t_r{
    	  width: calc(100% - 47px);
    	  font-size: 16px;
    	  font-family: PingFang SC;
    	  font-weight: bold;
    	  color: #BFF2F0;
    	  height: 42px;
    	  line-height: 42px;
    }
    .line{
    	  width: 100%;
    	  height: 1px;
    	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
    }
    .line1{
  	  width: 84%;
  	  height: 1px;
  	  background: #535A6B;
    }
    .line2{
  	  width: 15%;
  	  height: 1px;
  	  background: #535A6B;
  	  text-align: center;
    }
    .line3{
  	width: 90%;
  	height: 1px; 
  	  background: #36CFC9;
    }
    .line4{
  	  position: relative;
  	  left: 50%;
  	  transform: translateX(-50%);
  	  width: 15px;
  	  height: 1px;
  	  background: #36CFC9;
    }
	.out{
		width: 60px;
		height: 24px!important;
		line-height: 24px!important;
		background: rgba(54, 207, 201, 0.2);
		border: 1px solid #36CFC9;
		color: #36CFC9!important;
		padding: 0;
	}
	.del{
		width: 60px;
		height: 24px!important;
		line-height: 24px!important;
		background:  rgba(215,140,71, 0.2);
		border: 1px solid #D78C47;
		color: #D78C47!important;
		padding: 0;
	}
  .warehousing_record .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  .detail_right{
  	width: 35%;
  	height:100%; 
  	/* background: rgba(10,23,56,0.7); */
  }
  .b_middle_item{
  	width: 100%;
  	background: rgba(10,23,56,0.7);
  	padding:0 20px;
  	box-sizing: border-box;
  	margin-bottom: 20px;
	
  }
  .b_middle_item:last-child{
  	height: calc(47% - 20px);
  }
  .b_middle_item:nth-child(2){
  	height: calc(35% - 14px);
  	/* flex: 3; */
  }
  .b_middle_item:first-child{
  	height: calc(18% - 6px);
  	/* flex: 2; */
  }
  .t_box{
	  margin-top: 10px;
	  width: 100%;
	  height: calc(100% - 70px);
	  overflow: auto;  
  }
  .data_item{
	 /* width: 40%; */
	 width: 40%;
	  /* background-color: red; */
  	  float: left;
  	  margin-right: 20%;
  	  font-size: 10px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #BFF2F0;
  	  margin-bottom:3%;
  	  /* height: 16px; */
  	  display: flex;
  	  align-items: center;
    }
    .data_item:nth-child(2n+1){
  	  margin-right: 0;
    }
    .data_item div{
  	  display: inline-block;
  	  min-width: 60px!important;
  	  text-align:justify;
  	  height: 12px;
  	  line-height: 12px;
  	  
    }
    .data_item div i{
      display:inline-block;
  	  height: 1px;
      width:100%;
    }
    .data_number{
  	  display: inline-block;
  	  min-width: 50px;
  	  margin-left: 12px;
  	  font-size: 10px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #FFFFFF;
    }
    .warn_item{
  	  width: 100%;
  	  height: 16px;
  	  font-size: 12px;
  	  font-family: PingFang SC;
  	  color: #BFF2F0;
  	  margin-bottom: 5%;
  	  display: flex;
  	  justify-content: start;
  	  align-items: center;
    }
    .quan{
  	  display: inline-block;
  	  width: 6px;
  	  height: 6px;
  	  background-color:#ED9845;
  	  margin-right: 10px;
	  border-radius: 50%;
    }
    .red{
  	  display: inline-block;
  	  width: 6px;
  	  height: 6px;
  	  margin-right: 10px;
  	  background-color: #CF3636!important;
	   border-radius: 50%;
    }
	.blue{
	   background-color: #36CFC9!important;
	}
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
 /* .marks{
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: rgba(0,0,0,0.6);
  } */
  /deep/ .el-table::before{
	  background-color: rgba(255,255,255,0);
  }
  /deep/.el-table{
	  background-color: #0B1326;
	  height: calc(100% - 110px)!important;
  }
  /deep/.el-table th, .el-table tr{
	  background-color: rgba(4,33,95,0.9)!important;
	  color: #FFFFFF;
	  padding: 0;
	  height: 40px!important;
  }
  /deep/.el-table--enable-row-transition .el-table__body td{
	 /* background: #0B1326; */
	 background-color: rgba(11,19,38,0.9);
	 color: #FFFFFF;
  }
  /deep/.el-table tbody tr:hover>td { 
  	 /* background:rgba(54,207,201,0.6); */
	background:rgba(12,15,16,0.8);
  	 color: #FFFFFF;
  }
  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td{
	  /* background:rgba(12,15,16,0.8); */
	  /* background:  rgba(4,33,95,0.9); */
	  background-color: rgba(11,19,38,0.9);
	  color: #FFFFFF;
  }
  /deep/.el-table__expand-icon{
	  height: 40px;
	  line-height: 40px;
	  color: #FFFFFF;
  }
  /deep/.el-form--inline .el-form-item__label{
	  color: #FFFFFF;
  }
  /deep/.el-form--inline .el-form-item{
	  width: 100%;
	  margin-bottom: 0;
  }
 /* /deep/.el-form--inline:hover{
	  background-color: red;
  } */
  /deep/ tr{
	  background-color: transparent!important;
  }
  /deep/.el-select{
	  margin-right: 30px;
  }
  /deep/.el-select .el-input__inner{
  	  height: 48px;
  	  width: 262px;
  	  background-color: rgba(11,19,38,0.5);
  	  border: 1px solid #36CFC9;
  	  color: #FFFFFF !important;
  }
  /deep/.el-select .el-input.is-focus .el-input__inner{
	  border: 1px solid #36CFC9;
  }
  /deep/.el-input__inner{
	  height: 48px;
	  width: 262px;
	  background-color: rgba(11,19,38,0.5);
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
  }
  /deep/.el-input__suffix{
	  color: #FFFFFF !important;
  }
  /deep/.el-pagination__jump{
  	color: #FFFFFF;
  }
  /deep/.el-pager li{
	  width: 40px;
	  height: 40px;
	  line-height: 40px;
	  background: #0C0F10;
	  border: 1px solid #4B5052;
	  color: #FFFFFF;
	  padding: 0;
	  margin: 0 10px;
  }
  /deep/.el-pagination .btn-next, .el-pagination .btn-prev{
	 width: 40px;
	 height: 40px;
	 line-height: 40px;
	 background: #0C0F10;
	 border: 1px solid #4B5052;
	 color: #FFFFFF;
	 padding: 0; 
  }
  /deep/.el-pagination button:disabled{
	width: 40px;
	height: 40px;
	line-height: 40px;
	background: #0C0F10;
	opacity: 0.5;
	border: 1px solid #4B5052;
	color: #FFFFFF;
	padding: 0;  
  }
  /deep/.el-pagination__jump{
	  height: 40px!important;
	  line-height: 40px!important;
  }
  /deep/.el-pagination__editor.el-input .el-input__inner{
	  width: 80px;
	  border-color: #FFFFFF
  }
  /deep/.el-pager li.active{
	  background: #0B1326;
	  /* background: rgba(54, 207, 201, 0.2); */
	  border: 1px solid #36CFC9;
	  font-weight: bold;
  }
  /deep/.el-pagination .btn-prev{
	 width: 40px;
	 height: 40px;
	 line-height: 40px;
	 background: #0C0F10;
	 opacity: 0.5;
	 border: 1px solid #4B5052;
	 color: #FFFFFF;
	 padding: 0; 
  }
 .marks{
 	position: absolute;
 	top: 0;
 	left: 0;
 	width: 100%;
 	height: 100%;
 	background: rgba(0,0,0,0.8);  
 }
 .marks_box{
 	width: 436px;
 	height: 271px;
 	background: rgba(10, 23, 56, 0.7);
 	border: 2px solid #36CFC9;
 	position: absolute;
 	left: 50%;
 	top: 50%;
 	transform: translate(-50%,-50%);
 }
 .close{
	 position: absolute;
	 right: 15px;
	 top: 15px;
	 color: #FFFFFF;
	 font-size: 20px;
 }
 .marks_top{
 	width: 100%;
 	height: 77px;
 	border-left: 5px solid #36CFC9;
 	padding:15px 0  0 25px;
 	box-sizing: border-box;
 	margin-bottom: 30px;
 }
 .marks_name{
 	width: 50%;
 	height:48px ;
 	line-height: 48px;
 	background-image: linear-gradient(to right, rgba(54,207,201,0.5) , rgba(255,255,255,0));
 	color: #FFFFFF;
 	font-size: 16px;
 	font-family: PingFang SC;
 	font-weight: bold;
 	text-align: left;
 	padding-left:20px;
 	box-sizing: border-box;
 	letter-spacing: 2px;
 }
 .form{
	 width: 400px;
	 height: 48px;
	 display: flex;
	 align-items: center;
	 align-items: center;
	 position: relative;
	 left: 50%;
	 transform: translateX(-50%);
	 margin-bottom: 30px;
 }
 .label{
	 width: 100px;
	 height: 20px;
	 font-size: 16px;
	 font-family: PingFang SC;
	 color: #BFF2F0;
	 letter-spacing: 2px;
	 margin-right: 15px;
 }
 #myChart_equipment{
     width: 100%;
	 /* height: 300px; */
 	height:calc(100% - 50px);
}
 /deep/.el-date-editor .el-range__icon{
 	  color: #FFFFFF!important;
 	  margin-top: 5px;
 }
 /deep/.el-date-editor .el-range-input{
 	  background-color: transparent;
 	  color: #FFFFFF !important;
 }
 /deep/.el-date-editor .el-range-separator{
 	  color: #FFFFFF!important;
 	  margin-top: 10px;
 }
</style>
<style>
/* // 级联选择器样式 */
.el-select-dropdown {
    background-color: rgba(11,19,38);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
   /* font-weight: 400; */
    color: #fff!important;
    line-height: 20px;
    border: none;
    }
.el-select-dropdown__item{
	color: #fff!important;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
	background-color: rgba(0,0,0,1);
}
.el-select-dropdown__item.selected{
	color: #36CFC9!important;
}
</style>


